import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LandingPageModel } from 'shared/models/landingPage.model';
import {
	SelectableItem,
	SelectableList,
} from 'shared/classes/selectableList/selectableList';
import { LocalizationModel } from 'shared/models/localization.model';
import { LocalizationService } from 'shared/services/localization.service';
import { LandingPageService } from 'shared/services/landingPage.service';
import { Cnames, PublishService } from 'shared/services/publish.service';
import { TextService } from 'shared/services/text.service';
import { TranslatorModel } from 'shared/models/translator.model';
import { TranslationModel } from 'shared/models/translation.model';
import { TranslationState } from 'shared/enums/translationState.enum';
import { UIModule } from '@bannerflow/ui';
import { FilterPipe } from 'shared/pipes/filter.pipe';
import { EllipsisMiddlePipe } from 'shared/pipes/ellipsisMiddle.pipe';
import { TranslationService } from 'design/translation.service';
import { BFNotificationService } from '../../../../libs/material';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationRowComponent } from './translationRow.component';
import { SelectConfigModel } from 'shared/models/selectConfig.model';
import { SelectItemModel } from 'shared/models/selectItem.model';

@Component({
	styleUrls: ['versions.component.scss'],
	selector: 'versions',
	templateUrl: 'versions.component.html',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		TranslationRowComponent,
		FilterPipe,
		EllipsisMiddlePipe,
		UIModule,
	],
})
export class VersionsComponent implements OnInit {
	public callback: Function;
	public loading: boolean;
	public originalTranslation: EditableTranslation;
	public landingPage: LandingPageModel;
	public showAddVersions: boolean;
	public isValid: boolean;
	public newTranslations: EditableTranslation[] = [];
	public existingTranslations: EditableTranslation[] = [];
	public step: Step = Step.SelectNewVersions;
	public localizations = new SelectableList<LocalizationModel>();
	public isEditingTranslations: boolean = false;
	public hasTextsInOriginalTranslation: boolean;
	public localizationSearchQuery: string;

	private routeCommand = '../design';

	@ViewChild('step2', { static: true }) private step2: ElementRef;

	constructor(
		private readonly localizationService: LocalizationService,
		private readonly landingPageService: LandingPageService,
		private readonly publishService: PublishService,
		private readonly notificationService: BFNotificationService,
		private readonly textService: TextService,
		private readonly translationService: TranslationService,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
	) {
		this.landingPageService.translationsChange.subscribe(
			this.setEditTranslations.bind(this),
		);
	}

	public ngOnInit(): void {
		this.loading = true;
		this.setEditTranslations();
		this.updateEditingStatus();
	}

	private setEditTranslations() {
		this.existingTranslations = [];
		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			this.landingPage = landingPage;
			this.hasTextsInOriginalTranslation =
				this.landingPage.originalTranslation.texts &&
				this.landingPage.originalTranslation.texts.length > 0;
			this.publishService.getCnames().then((cnames) => {
				this.localizationService
					.get()
					.then((localizations: LocalizationModel[]) => {
						let sortedLocalizations = localizations.sort(
							(a: LocalizationModel, b: LocalizationModel) => {
								return a.name.toUpperCase() >
									b.name.toUpperCase()
									? 1
									: -1;
							},
						);
						this.localizations =
							SelectableList.fromArray<LocalizationModel>(
								sortedLocalizations,
							);
						this.originalTranslation =
							this.createEditableTranslationForExistingTranslation(
								cnames,
								this.landingPage.originalTranslation,
							);
						if (this.landingPage.translations) {
							this.landingPage.translations.forEach(
								(translation) => {
									let translationEditable =
										this.createEditableTranslationForExistingTranslation(
											cnames,
											translation,
										);
									let localization = this.localizations.find(
										(
											loc: SelectableItem<LocalizationModel>,
										) => {
											return (
												loc.data.localizationId ===
												translation.localizationId
											);
										},
									);
									if (localization) {
										translationEditable.translatorsAndNone =
											this.createTranslatorSelectConfig(
												localization.data,
												/*addNullOption*/ true,
											);
										translationEditable.translators =
											this.createTranslatorSelectConfig(
												localization.data,
												/*addNullOption*/ false,
											);
									} else {
										translationEditable.translatorsAndNone =
											new SelectConfigModel();
										translationEditable.translators =
											new SelectConfigModel();
									}
									translationEditable.translators.options.width = 300;
									this.existingTranslations.push(
										translationEditable,
									);
								},
							);
						}

						this.sortTranslations(this.existingTranslations);
						this.loading = false;
						this.updateEditingStatus();
					});
			});
		});
	}

	private sortTranslations(editableTranslation: EditableTranslation[]): void {
		editableTranslation.sort(
			(a: EditableTranslation, b: EditableTranslation) => {
				return a.translation.name.toUpperCase() >
					b.translation.name.toUpperCase()
					? 1
					: -1;
			},
		);
	}

	private createTranslatorSelectConfig(
		localization: LocalizationModel,
		addNullOption: boolean,
	): SelectConfigModel {
		var options: SelectItemModel[] = [];

		if (addNullOption) {
			// null option
			options.push(new SelectItemModel("Don't send", null));

			// seperator
			options.push(new SelectItemModel(null, null, true));
		}

		// translators
		localization.translators.forEach((translator: TranslatorModel) => {
			let translatorItem: SelectItemModel = new SelectItemModel(
				translator.name,
				translator,
			);
			translatorItem.icon = 'email';
			options.push(translatorItem);
		});

		return new SelectConfigModel(options);
	}

	public validate = (
		publishSlug: string,
		wrapper: EditableTranslation,
		useGeneratedSlug = false,
	): Promise<any> => {
		return new Promise((resolve: Function) => {
			// publish slug is set to empty
			if (!publishSlug) {
				wrapper.isValid = false;
				this.isValid = false;

				return;
			}

			let allTranslations = [
				this.originalTranslation,
				...(this.existingTranslations || []),
				...(wrapper.isNew ? this.newTranslations : []),
			];

			// remove this translation wrapper and select the slugs
			let allSlugs = allTranslations
				.filter((t) => t !== wrapper)
				.map((t) => t.translation.publishSlug);
			// server checks so there are no name collition within the brand or the slugs we provide
			let requestNr = ++wrapper.requestNumber;
			wrapper.isLoading = true;
			this.publishService
				.checkNameAvailability(
					publishSlug,
					this.landingPage.id,
					allSlugs,
					wrapper.translation.id ? wrapper.translation.id : null,
				)
				.then((slug: any) => {
					if (requestNr === wrapper.requestNumber) {
						wrapper.publishSlugIsTaken = slug.taken;
						wrapper.isValid = !slug.taken;

						if (wrapper.isNew) {
							if (useGeneratedSlug) {
								wrapper.translation.publishSlug =
									slug.availableSlug;
								wrapper.publishSlugIsTaken = false;
								wrapper.isValid = true;
							}

							resolve(slug.publishSlug);
						}

						resolve(slug.publishSlug);

						wrapper.isLoading = false;
						this.validateAll();
					}
				});
		});
	};

	private validateAll(): void {
		this.isValid = this.newTranslations.find(
			(newTranslation: EditableTranslation) => {
				return !newTranslation.isValid || newTranslation.isEditing;
			},
		)
			? false
			: true;
	}

	private createEditableTranslationForExistingTranslation(
		cnames: Cnames,
		translation: TranslationModel,
	): EditableTranslation {
		let wrapper = new EditableTranslation();
		wrapper.translation = translation;
		wrapper.publishSlugIsTaken = false;
		wrapper.isLoading = false;
		wrapper.cName = this.publishService.getCname(
			translation.localizationId,
			this.landingPage.accountSlug,
			this.landingPage.brandId,
			cnames,
		);

		return wrapper;
	}

	private createEditableTranslationForNewTranslation(
		localization: LocalizationModel,
		cnames: any,
	): EditableTranslation {
		// init wrapper
		let wrapper = new EditableTranslation();
		wrapper.isNew = true;
		wrapper.translators = this.createTranslatorSelectConfig(
			localization,
			/*addNullOption*/ false,
		);
		wrapper.translatorsAndNone = this.createTranslatorSelectConfig(
			localization,
			/*addNullOption*/ true,
		);
		wrapper.cName = this.publishService.getCname(
			localization.localizationId,
			this.landingPage.accountSlug,
			this.landingPage.brandId,
			cnames,
		);

		// init translation
		let translation = new TranslationModel();
		translation.notifyOnDone = [];
		translation.localizationId = localization.localizationId;
		translation.name = localization.name;
		translation.culture = localization.cultureCode;
		translation.cultureName = localization.cultureName;
		translation.publishSlug = this.publishService.getDefaultPublishName(
			cnames.namePattern as string,
			this.landingPage.name,
			localization.cultureCode,
		);
		translation.fallbackUrl =
			localization.fallbackUrl || 'http://www.bannerflow.com';

		//If the original text contains any texts, set default translator to send the translation to
		if (this.hasTextsInOriginalTranslation) {
			//Get default translator for language
			let defaultTranslator = wrapper.translatorsAndNone.selectItems.find(
				(menuItem: SelectItemModel) => {
					return menuItem.value && menuItem.value.isDefault;
				},
			);

			//Set default translator as selected translator if any
			translation.translator = defaultTranslator
				? defaultTranslator.value
				: null;
		}
		translation.translationState = translation.translator
			? TranslationState.Pending
			: TranslationState.None;
		// set translation on wrapper
		wrapper.translation = translation;

		return wrapper;
	}

	private createNewTranslations(
		selectedLocalizations: LocalizationModel[],
		cnames: any,
	): void {
		let localization = selectedLocalizations.shift();
		let wrapper = this.createEditableTranslationForNewTranslation(
			localization,
			cnames,
		);
		this.validate(wrapper.translation.publishSlug, wrapper, true).then(
			() => {
				this.newTranslations.push(wrapper);
				if (selectedLocalizations.length) {
					this.createNewTranslations(selectedLocalizations, cnames);
				} else {
					setTimeout(() => {
						if (this.step2 && this.step2.nativeElement) {
							this.step2.nativeElement.classList.add(
								'removeAnimation',
							);
						}
					}, 1000);
				}
				this.sortTranslations(this.newTranslations);
			},
		);
	}

	private handleStep(step: Step) {
		switch (step) {
			case Step.SelectNewVersions:
				this.newTranslations = [];
				break;
			case Step.EditNewVersions:
				this.publishService.getCnames().then((cnames: any) => {
					this.createNewTranslations(
						this.localizations.getSelected(),
						cnames,
					);
				});
				break;
			case Step.AddNewVersions:
				this.loading = true;
				let newTranslations = this.newTranslations.map(
					(t) => t.translation,
				);

				this.translationService
					.create(this.landingPage.id, newTranslations)
					.then((translations: TranslationModel[]) => {
						this.landingPageService.addTranslations(translations);
						this.loading = false;

						// If only one new added version, go to it!
						if (translations.length === 1) {
							this.textService.setCurrentTranslation(
								translations[0],
							);
						}

						this.showAddedNotification(translations);
						this.cancel();
					});

				// TODO: handle failure

				break;
			default:
				break;
		}
	}

	private showAddedNotification(translations: TranslationModel[]): void {
		let translationsString = '';
		translations.forEach((t: TranslationModel, index: number) => {
			let suffix = index === translations.length - 1 ? '' : ', ';
			translationsString += `'${t.name}'${suffix}`;
		});

		let notificaiton = `${translationsString} has been added to your Landing Page.`;
		this.notificationService.show(
			notificaiton,
			'success',
			'top',
			3500,
			'finished',
		);
	}

	/*** VIEW FUNCTIONS ***/

	// goes to the next step when adding versions
	public next(): void {
		this.step++;
		this.updateEditingStatus();
		this.handleStep(this.step);
	}

	// goes to the previous step when adding versions
	public prev(): void {
		this.step--;
		this.updateEditingStatus();
		this.handleStep(this.step);
	}

	// opens the version picker (only available in step 1)
	public openAddVersions(): void {
		this.localizations.deselectAll();
		this.showAddVersions = true;
	}

	// closes the version picker (only available in step 1)
	public closeAddVersions(): void {
		this.localizations.deselectAll();
		this.showAddVersions = false;
	}

	private updateEditingStatus(): void {
		this.isEditingTranslations =
			this.originalTranslation?.isEditing ||
			this.existingTranslations.some(
				(translation) => translation?.isEditing,
			) ||
			this.newTranslations.some((translation) => translation?.isEditing);
	}

	public isEditing(): boolean {
		if (this.step == Step.SelectNewVersions) {
			return this.originalTranslation.isEditing ||
				this.existingTranslations.find(
					(translation: EditableTranslation) => {
						return translation.isEditing;
					},
				)
				? true
				: false;
		} else {
			return this.newTranslations.find(
				(newTranslation: EditableTranslation) => {
					return newTranslation.isEditing;
				},
			)
				? true
				: false;
		}
	}

	public navigateTo(
		routeCommand: string,
		activatedRoute: ActivatedRoute,
	): any {
		return this.router.navigate([routeCommand], {
			relativeTo: activatedRoute,
		});
	}

	public cancel(): void {
		return this.navigateTo(this.routeCommand, this.activatedRoute);
	}
}

enum Step {
	SelectNewVersions = 1,
	EditNewVersions,
	AddNewVersions,
}

export interface IEditableTranslation {
	publishSlugIsTaken: boolean;
	requestNumber: number;
	isLoading: boolean;
}

// keeps track of changes done to translations in this component and the translation row component (sub component to this)
export class EditableTranslation implements IEditableTranslation {
	public translation: TranslationModel;
	public publishSlugIsTaken: boolean;
	public requestNumber: number = Number.MIN_VALUE;
	public isLoading: boolean;
	public isValid: boolean = true;
	public isEditing: boolean = false;
	public cName: string;
	public isNew: boolean;
	public translators: SelectConfigModel;
	public translatorsAndNone: SelectConfigModel;
}
