import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@auth0/auth0-angular';
import { switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FileModel } from 'shared/models/file.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private httpClient: HttpClient, private authService: AuthService) { }

  private getAuthHeaders(): Observable<HttpHeaders> {
    return this.authService.getAccessTokenSilently({ cacheMode: 'on' }).pipe(
      take(1),
      switchMap((token: string) => {
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${token}`
        });
        return [headers];
      })
    );
  }

  public downloadZip(landingPageId: string, accountSlug: string, brandId: string): Observable<Blob> {
    const downloadZipUrl = `${environment.API_URL}/api/landingpage/${accountSlug}/${brandId}/downloadfiles/${landingPageId}`;
    
    return this.getAuthHeaders().pipe(
      switchMap((headers: HttpHeaders) => {
        return this.httpClient.get(downloadZipUrl, {
          headers: headers,
          responseType: 'blob'
        });
      })
    );
  }

  public downloadFile(file: FileModel, landingPageId: string, accountSlug: string, brandId: string): Observable<Blob> {
    const downloadFileUrl = `${environment.API_URL}/api/landingpage/${accountSlug}/${brandId}/downloadfile/${landingPageId}/${file.id}`;

    return this.getAuthHeaders().pipe(
      switchMap((headers: HttpHeaders) => {
        return this.httpClient.get(downloadFileUrl, {
          headers: headers,
          responseType: 'blob'
        });
      })
    );
  }

  public uploadFile(formData: FormData, uploadUrl: string): Observable<any> {
    return this.getAuthHeaders().pipe(
      switchMap((headers: HttpHeaders) => {
        return this.httpClient.put(uploadUrl + 'uploadfile', formData, { headers: headers });
      })
    );
  }
}
