// libs
import { Injectable, EventEmitter } from '@angular/core';
import {FileModel} from "shared/models/file.model";
import {LandingPageService} from "shared/services/landingPage.service";
import {UserService} from "shared/services/user.service";
import {DocumentService} from "code/services/document.service";
import {State} from "shared/enums/state.enum";
import {FolderModel} from "shared/models/folder.model";
import {FileType} from "shared/enums/fileType.enum";
import {IFile} from "code/file.interface";
import {LandingPageModel} from "shared/models/landingPage.model";
import { take } from 'rxjs';
import { FileService } from './file.service';



@Injectable({ providedIn: 'root' })
export class CodeService {
    public onFileSelected: EventEmitter<FileModel> = new EventEmitter<FileModel>();
    public onFileNameChanged: EventEmitter<FileModel> = new EventEmitter<FileModel>();

    constructor(private landingPageService: LandingPageService,
                private documentService:DocumentService,
                private fileService: FileService) { }

    private preserveUniqueFilename(
        files: FileModel[], file: FileModel, depth: number, name: string) {

        if (!files || !file || files.length < 1)
            return;

        for (let i = 0; i < files.length; i++) {
            let existingFile = files[i];

            // if the new file share the name with an existing, not deleted file, the name of the new file is altered to be unique
            if (existingFile.name === file.name && existingFile.state !== State.Deleted) {
                let guardedName = (name || '');

                // find index of last period in filename, else set index to last position
                let periodIndex = guardedName.lastIndexOf('.');
                periodIndex = periodIndex < 0 ? guardedName.length : periodIndex;

                // insert the additional characters right before the period if it exists, else last
                let newName = guardedName.slice(0, periodIndex) + `-${depth}` + guardedName.slice(periodIndex);

                file.name = newName;
                i = files.length;

                // make sure name is still unique
                this.preserveUniqueFilename(files, file, ++depth, name);
            }
        }
    }

    public addFile(folder: FolderModel, file:FileModel = null, selectFile: boolean = true):FileModel{
        //Create empty text file if no file inputed
        if(!file) {
            file = new FileModel();
            file.stringContent = '';
            file.sortName = 'a' + Math.random();
            file.name = '';
            file.type = FileType.Text;
        }

        file.state = State.New;

        this.preserveUniqueFilename(folder.files, file, 1, file.name);

        //Add the new file to its folder
        folder.files.push(file);

        //Select the new file in the file browser
        if(selectFile)
            this.documentService.openFile(file);

        return file;
    }

    public addFiles(folder: FolderModel, files:FileModel[]): void{
        files.forEach((file: FileModel, index: number)=>{
            this.addFile(folder, file, index === files.length - 1);
        });

        //Set dirty flag
        this.landingPageService.toggleDirty(true);
    }

    public removeFile(fileToRemove: IFile): void {

        //Close the open document containing the file
        this.documentService.closeDocument(this.documentService.getDocumentWithFile(fileToRemove as FileModel));

        this.landingPageService.get().then((landingPage: LandingPageModel) => {
            //If its a new file, remove it from file list
            if(fileToRemove.state === State.New) {
                    var remove = (files: FileModel[]) => {
                        var indexToRemove = -1;

                        files.forEach((file: FileModel, index: number) => {
                            if(file === fileToRemove){
                                indexToRemove = index;
                            }
                        });

                        if(indexToRemove != -1)
                            files.splice(indexToRemove, 1);

                        return indexToRemove != -1;
                    }
                    remove(landingPage.rootFolder.files);
            } else {
                // If the file is not new, set deleted state
                fileToRemove.state = State.Deleted;
            }

            let fileId;
            landingPage.rootFolder.files.forEach((file: FileModel) => {
                if (file === fileToRemove) {
                    fileId = file.id;
                }
            });

            // remove resource translations
            if (landingPage.originalTranslation && landingPage.originalTranslation.resourceTranslations) {
                landingPage.originalTranslation.resourceTranslations = landingPage.originalTranslation
                    .resourceTranslations.filter((r) => { return r.originalResourceId !== fileId });
            }

            if (landingPage.translations) {
                landingPage.translations.forEach(t => {
                    if (t.resourceTranslations) {
                        t.resourceTranslations = t.resourceTranslations.filter((r) => { return r.originalResourceId !== fileId });
                    }
                });
            }

            //Set dirty flag
            this.landingPageService.toggleDirty(true);

        });
    }

    public renameFile(file: FileModel, name: string): void {
        if (file.name !== name) {
            if (file.state !== State.New) file.state = State.Modified;
            file.name = name;
            (file as any).setName(name);
            this.landingPageService.toggleDirty(true);
            this.onFileNameChanged.emit(file as FileModel);
        }
    }

    public downloadFile(file: FileModel): void {
        this.landingPageService.get().then((landingPage: LandingPageModel) => {
          // todo: file id should be translated resource id if translated

          this.fileService.downloadFile(file, landingPage.id, landingPage.accountSlug, landingPage.brandId).pipe(take(1))
            .subscribe(blob => {
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = file.name || 'downloaded-file';
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              window.URL.revokeObjectURL(url);
            });
        });
      }
}

