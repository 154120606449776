<div class="translationTextInput" [ngClass]="{'translationTextInput--active': active}">
    <div class="translationTextInput__textNameAndButtons">
        <div class="translationTextInput__leftColumn">
            <div class="translationTextInput__textNumber">
                {{ index }}
            </div>
            <div class="translationTextInput__textNameInput"
                *ngIf="isOriginal"
                [(bfInlineEdit)]="translation.originalText.name"
                (bfInlineEditDone)="setName()"></div>
            <div class="translationTextInput__textNameInput"
                *ngIf="!isOriginal">
                {{ translation.originalText.name || 'untitled' }}
            </div>
        </div>
        <div class="translationTextInput__htmlButtonContainer"
             bfTooltip="Toggle HTML mode">
            <i (mouseup)="htmlMode = !htmlMode"
                [ngClass]="{'translationTextInput__htmlButton--active': htmlMode}"
                class="bf-icon bf-icon-code translationTextInput__htmlButton"></i>
        </div>
    </div>
    <div class="translationTextInput__original"
        [ngClass]="{ 'translationTextInput__original--collapsed' : translation.originalText.value.length > 150 && !showMore  }"
        *ngIf="translation.originalText != translation.text">
        {{ translation.originalText.value | stripTags }}
        <div class="translationTextInput__originalShowAll"
             *ngIf="translation.originalText.value.length > 150"
             (click)="showMore = !showMore">
            <i class="bf-icon bf-icon-more"></i>
        </div>
    </div>
    <div #htmlSyntaxHelper style="display: none;"></div>
    <iframe #textIframe class="translationTextInput__textIframe"  src="about:blank"></iframe>
</div>
