import {
    Component,
    Input,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import {
  BFMenuItem,
  BFMenuDirective,
  BFContextMenuDirective,
  BFInlineEditDirective,
  BFOrderByPipe
} from '../../../../../../../libs/material/index';
import {CommonModule} from "@angular/common";
import {
  FileBrowserFileComponent
} from "code/views/code/components/fileBrowser/fileBrowserFile/fileBrowserFile.component";
import {FileBrowserItem} from "code/views/code/components/fileBrowser/fileBrowserItem/fileBrowserItem";
import {CodeService} from "code/services/code.service";
import {DocumentService} from "code/services/document.service";
import {FolderModel} from "shared/models/folder.model";
import {State} from "shared/enums/state.enum";
import { LPBConfirmDialogService } from 'shared/services/lpb-confirm-dialog.service';

@Component({
    selector: 'fileBrowserFolder',
    styleUrls: ['fileBrowserFolder.component.scss'],
    templateUrl: 'fileBrowserFolder.component.html',
    inputs: ['item', 'depth', 'parentFolder'],
    outputs: ['fileSelect', 'nameChange'],
    standalone: true,
    imports: [CommonModule, BFContextMenuDirective, BFInlineEditDirective, BFMenuDirective, FileBrowserFileComponent, BFOrderByPipe]
})
export class FileBrowserFolderComponent extends FileBrowserItem implements AfterViewInit {
    @Input() public selectHandler: any;
    @Input() public collapsed = false;

    @ViewChild('bfInlineEditReference', { static: true }) public inlineEditDirective: BFInlineEditDirective;

    constructor(
        private readonly _codeService: CodeService,
        private readonly _lpbDialogService: LPBConfirmDialogService,
        private readonly _documentService: DocumentService) {

        super(_codeService, _lpbDialogService, _documentService);
    }

    ngAfterViewInit() {
        this.config.menuItems.unshift(new BFMenuItem('New folder', (item, event) => { this.addFolder() }))
        this.config.menuItems.unshift(new BFMenuItem('New file', (item, event) => { this.addFile() }))

        //Only do this if the item is not the rootFolder or index.html
        if (this.inlineEditDirective) {
            this.initFileItem(this.inlineEditDirective);
        }
    }

    public addFolder($event?: any) {
        // this.collapsed = false;
        // let newFolder = new FolderModel();
        // newFolder.name = "";
        // newFolder.state = State.New;

        // (this.item as FolderModel).folders.push(newFolder);

        // if ($event) {
        //     $event.stopPropagation();
        //     $event.preventDefault();
        // }
    }

    public addFile($event?: any) {
        // this.collapsed = false;

        // this.codeService.addFile(this.item as FolderModel, null);

        // if ($event) {
        //     $event.stopPropagation();
        //     $event.preventDefault();
        // }
    }

    public toggleCollapsed() {
        this.collapsed = !this.collapsed;
    }
}
