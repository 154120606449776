import {Component, ComponentRef, ElementRef, Input, NgZone, ViewChild} from "@angular/core";
import {NgClass, NgIf} from "@angular/common";
import {
  BFComponentContainer,
  BFInlineEditDirective,
  BFMaterial,
  BFTooltipDirective
} from "../../../../../../../libs/material";
import {StripTagsPipe} from "shared/pipes/stripTags.pipe";
import {TranslateTextModel} from "design/views/design/components/translationSidebar/translationSidebar.component";
import {TextService} from "shared/services/text.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {DesignService} from "design/design.service";
import {TextModel} from "shared/models/text.model";
import {HotkeysService} from "shared/services/hotkeys.service";
import {InspectorComponent} from "design/views/design/components/designEditor/inspector/inspector.component";
import {ScribeWrapper} from "shared/wrappers/scribe.wrapper";

interface IframeInputs {
    textInput: HTMLDivElement;
    htmlInput: HTMLTextAreaElement;
}

@Component({
    styleUrls: ['translationTextInput.component.scss'],
    selector: 'translationTextInput',
    templateUrl: 'translationTextInput.component.html',
    standalone: true,
    imports: [NgClass, NgIf, BFInlineEditDirective, BFTooltipDirective, StripTagsPipe]
})
export class TranslationTextInputComponent {

    @Input() public translation: TranslateTextModel;
    @Input('index') public index: number;
    @Input('isOriginal') public isOriginal: boolean;
    @ViewChild('textinput', { static: true }) public textInputElementRef: ElementRef;
    @ViewChild('textIframe', { static: true }) public textIframeElementRef: ElementRef;
    @ViewChild('htmlSyntaxHelper', { static: true }) public htmlSyntaxHelper: ElementRef;

    public scribe: ScribeWrapper;
    public active: boolean = false;
    public iframeWindow;
    public onClick: Function;
    public onIframeMouseEvent: Function;
    public selectedTextTimeout: any;
    public initTimeout: any;
    public onFocus: any;
    public onBlur: any;
    public onTextChange: any;
    public onBlockElementChange: any;
    public onSelectText: any;
    public _htmlMode = false;
    public textInput: HTMLDivElement;
    public htmlInput: HTMLTextAreaElement;
    public inspector: ComponentRef<InspectorComponent>;
    public showMore: boolean;

    constructor(private textService: TextService,
        private ngZone: NgZone,
        private bfMaterial: BFMaterial,
        private designService: DesignService,
        private landingPageService: LandingPageService,
        private bfComponentContainer: BFComponentContainer,
        private hotkeysService: HotkeysService) {
    }

    ngAfterViewInit() {
        let text = this.translation.text ? this.translation.text : this.translation.originalText;
        this.iframeWindow = this.textIframeElementRef.nativeElement.contentWindow;
        const iframeInputs = this.initIframe(text.value);
        this.textInput = iframeInputs.textInput;
        this.htmlInput = iframeInputs.htmlInput;

        let doc = this.iframeWindow.document;

        //Stupid firefox won't work if not dubble initializing the iframe
        this.initTimeout = setTimeout(() => {
            const iframeInputs = this.initIframe(text.value);
            this.textInput = iframeInputs.textInput;
            this.htmlInput = iframeInputs.htmlInput;
            this.htmlInput.onfocus = () => {
                this.ngZone.run(() => {
                    this.active = true;
                });
            };
            this.htmlInput.onblur = () => {
                this.ngZone.run(() => {
                    this.active = false;
                });
            };

            this.initScribe();

            //Text changed externally
            this.onTextChange = this.textService.textChange.subscribe((text: TextModel) => {
                if (this.translation.originalText.key === text.key) {
                    this.setText(text.value);
                    this.setScribeContent(text.value, true);
                }
            });

            //Block element mode changed externally
            this.onBlockElementChange = this.textService.blockElementChange.subscribe((text: TextModel) => {
                if (this.translation.originalText.key === text.key) {
                    this.initScribe();
                }
            });

            this.onSelectText = this.designService.selectedTextChange.subscribe((text?: TextModel) => {
                this.active = text && this.translation.originalText.key === text.key;
            });

            //Setup save hotkeys to work when iframe has focus
            this.hotkeysService.bindKeyOnElement(['ctrl+s', 'command+s'], this.iframeWindow.document, (e: any) => {
                this.ngZone.run(() => {
                    this.hotkeysService.trigger('ctrl+s');
                });
                e.preventDefault();
                return false;
            });


            this.initClickBubbles();
        }, 10);
    }

    public get htmlMode() {
        return this._htmlMode;
    }

    public set htmlMode(yes: boolean) {
        if (yes) {
            this.textInput.style.display = 'none';
            this.htmlInput.style.display = 'block';
            this.setText(this.scribe.getContent());
            this.htmlInput.value = this.scribe.getContent();
            this.htmlInput.focus();
        }
        else {
            this.textInput.style.display = 'block';
            this.htmlInput.style.display = 'none';
            this.setText(this.scribe.getContent());
        }
        this._htmlMode = yes;
    }

    private isSyntaxValidHtml(text: string): boolean {
        // We just insert in a <div> element and let the browser decide whether the html is valid or not.
        // If it is valid the html inside the div should remain unchanged.
        (this.htmlSyntaxHelper.nativeElement as HTMLDivElement).innerHTML = text;
        return (this.htmlSyntaxHelper.nativeElement as HTMLDivElement).innerHTML === text;
    }

    public get height() {
        return (this.textIframeElementRef.nativeElement as HTMLIFrameElement).getBoundingClientRect().height;
    }

    public getBoundingClientRect() {
        return (this.textIframeElementRef.nativeElement as HTMLIFrameElement).getBoundingClientRect();
    }

    private initScribe() {
        let text = this.translation.text ? this.translation.text : this.translation.originalText;

        this.destroyScribe();

        //Create new scribe instance
        this.scribe = new ScribeWrapper(this.ngZone,
            this.textInput,
            true,
            this.translation.originalText.isBlockElement,
            text.value);

        //Text changed in scribe instance
        this.htmlInput.addEventListener('focus', () => {
            this.active = true;
        });
        this.htmlInput.addEventListener('keyup', (event) => {
            const text = this.htmlInput.value;
            if (this.isSyntaxValidHtml(text)) {
                this.setText(text);
            }
        }, false);
        this.scribe.change.subscribe(() => {
            this.setText(this.scribe.getContent());
            this.selectedTextTimeout = setTimeout(() => {
                this.designService.selectText(this.translation.text, 'sidebar');
            }, 100);
        });

        //Focus gained in scribe instance
        this.onFocus = this.scribe.focus.subscribe((scribe) => {
            this.openInspector(this.scribe);
            this.active = true;

            this.clearSelectedTextTimeout();
            this.selectedTextTimeout = setTimeout(() => {
                this.designService.selectText(this.translation.text, 'sidebar');
            }, 100);
        });
    }

    private initIframe(html = ''): IframeInputs {
        this.iframeWindow = this.textIframeElementRef.nativeElement.contentWindow;
        const iframeBody = this.iframeWindow.document.body as HTMLBodyElement;

        iframeBody.innerHTML = `
            <style type="text/css">
                body {
                    padding: 0;
                    margin: 0;
                    font-size: 12px;
                    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
                }
                body > div, textarea {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    box-sizing: border-box;
                    padding: 10px 12px;
                    margin: 0;
                    outline: none;
                }
                textarea {
                    width: 100%;
                    resize: none;
                    border: none;
                    outline: none;
                    font-size: inherit;
                    font-family: inherit;
                }
                body > div {
                    display: block;
                    color: #363636;
                    word-wrap: break-word;
                    -webkit-line-break: after-white-space;
                }
                p {
                    margin: 0;
                    padding: 0;
                }
                ul, ol {
                    padding-left: 20px;
                }
            </style>
            <div contenteditable="true" spellcheck="false">${html}</div>
            <textarea spellcheck="false" style="display: none;" value="${html}"></textarea>
        `;
        return {
            textInput: iframeBody.getElementsByTagName('div')[0] as HTMLDivElement,
            htmlInput: iframeBody.getElementsByTagName('textarea')[0] as HTMLTextAreaElement,
        }
    }

    public setName() {
        this.landingPageService.toggleDirty(true);
    }

    private setText(value: string): void {
        this.translation.text = this.textService.setTextValue(this.translation.originalText.key, value, null, this.htmlMode);
    }

    private setScribeContent(content: string, preventEvent: boolean = false) {
        this.scribe.setContent(content || '', preventEvent);
    }

    private openInspector(scribe: ScribeWrapper): void {
        //Already opened on this element
        if (this.inspector && this.inspector.instance.scribe === scribe) {
            return;
        }

        this.inspector = this.bfComponentContainer.attach(InspectorComponent, this.bfMaterial.rootViewContainerRef);

        this.inspector.instance.show(scribe, this.textIframeElementRef.nativeElement).then((result: boolean) => {
            this.closeInspector();
        });
    }

    private closeInspector(scribe?: ScribeWrapper): void {
        if (this.inspector) {
            this.scribe.clearSelection();
            this.bfComponentContainer.detach(this.inspector);
            this.inspector = null;
        }
    }

    private isActive(command: string) {
        return this.scribe.isActive(command);
    }

    private initClickBubbles() {
        this.onIframeMouseEvent = (event: MouseEvent) => {
            // from http://stackoverflow.com/a/32010791/217408
            let mouseEvent = new MouseEvent(event.type, { bubbles: true });
            // TODO need to fix this
            // this.Renderer2.invokeElementMethod(this.textIframeElementRef.nativeElement, 'dispatchEvent', [mouseEvent]);
        };

        this.iframeWindow.addEventListener('click', this.onIframeMouseEvent);
        this.iframeWindow.addEventListener('mousedown', this.onIframeMouseEvent);
        this.iframeWindow.addEventListener('mouseup', this.onIframeMouseEvent);
    }

    private clearSelectedTextTimeout() {
        if (this.selectedTextTimeout) {
            clearTimeout(this.selectedTextTimeout);
            this.selectedTextTimeout;
        }
    }

    private destroyScribe() {
        if (this.scribe) {
            this.scribe.change.unsubscribe();
            this.scribe.destroy();
            this.scribe = null;
            this.onFocus.unsubscribe();
        }
    }

    ngOnDestroy() {

        //Make sure init doesn't come after this component is removed
        if (this.initTimeout) {
            clearTimeout(this.initTimeout);
        }

        this.destroyScribe();
        this.clearSelectedTextTimeout();

        if (this.iframeWindow) {
            this.iframeWindow.removeEventListener('click', this.onIframeMouseEvent);
            this.iframeWindow.removeEventListener('mousedown', this.onIframeMouseEvent);
            this.iframeWindow.removeEventListener('mouseup', this.onIframeMouseEvent);
        }

        if (this.onBlur)
            this.onBlur.unsubscribe();
        if (this.onTextChange)
            this.onTextChange.unsubscribe();
        if (this.onBlockElementChange)
            this.onBlockElementChange.unsubscribe();
        if (this.onSelectText)
            this.onSelectText.unsubscribe();
    }
}
