import {
	Component,
	OnChanges,
	Input,
	Output,
	EventEmitter,
} from '@angular/core';
import { ColorService } from '../colorPicker/color.service';
import { CommonModule } from '@angular/common';
import { UIModule } from '@bannerflow/ui';

@Component({
	styleUrls: ['swatches.component.scss'],
	selector: 'swatches',
	templateUrl: 'swatches.component.html',
	standalone: true,
	imports: [CommonModule, UIModule],
})
export class SwatchesComponent implements OnChanges {
	@Input('config') private config: any; // Should we have this or categories directly?
	@Input('color') color: string;
	@Output('colorChange') colorChange = new EventEmitter<string>(true);

	public rgbaColor: string;
	public categories: SwatchesCategory[];
	private selectedColor: SwatchesColor;

	constructor(private colorService: ColorService) {}

	public selectColor(color: string, emit: boolean = true, event?: Event) {
		if (this.colorService.validateColor(color)) {
			this.color = color;
			this.rgbaColor = this.toRgba(color);

			//only emit if this is a selection
			if (emit) this.colorChange.emit(color);

			if (event) {
				event.stopPropagation();
				event.preventDefault();
			}
		}
	}

	public toRgba(color: string) {
		return this.colorService.rgbText(color);
	}

	ngOnChanges(changes: any) {
		//If no config is se, use default colors
		if (!this.config) {
			this.setDefaultConfiguration();
		}

		this.categories = this.config.categories;
		this.selectColor(this.color, false);
	}

	private setDefaultConfiguration() {
		this.config = new SwatchesConfig([
			new SwatchesCategory(
				[
					new SwatchesColor('#000'),
					new SwatchesColor('#222'),
					new SwatchesColor('#444'),
					new SwatchesColor('#666'),
					new SwatchesColor('#888'),
					new SwatchesColor('#aaa'),
					new SwatchesColor('#ccc'),
					new SwatchesColor('#ddd'),
					new SwatchesColor('#eee'),
					new SwatchesColor('#fff'),
				],
				//Columns
				[
					//Deep red
					new SwatchesCategoryColumn([
						new SwatchesColor('#980000'),
						new SwatchesColor('#E6B8AF'),
						new SwatchesColor('#DD7E6B'),
						new SwatchesColor('#CC4125'),
						new SwatchesColor('#A61C00'),
						new SwatchesColor('#5B0F00'),
					]),
					//Red
					new SwatchesCategoryColumn([
						new SwatchesColor('#FF0000'),
						new SwatchesColor('#F4CCCD'),
						new SwatchesColor('#EA9999'),
						new SwatchesColor('#E06666'),
						new SwatchesColor('#CC0000'),
						new SwatchesColor('#660000'),
					]),
					//Orange
					new SwatchesCategoryColumn([
						new SwatchesColor('#FF9901'),
						new SwatchesColor('#FCE5CD'),
						new SwatchesColor('#F9CB9C'),
						new SwatchesColor('#F6B26B'),
						new SwatchesColor('#E69138'),
						new SwatchesColor('#783F05'),
					]),
					//Yellow
					new SwatchesCategoryColumn([
						new SwatchesColor('#FFFF02'),
						new SwatchesColor('#FFF2CC'),
						new SwatchesColor('#FFE599'),
						new SwatchesColor('#FFD966'),
						new SwatchesColor('#F1C233'),
						new SwatchesColor('#7F6000'),
					]),
					//Green
					new SwatchesCategoryColumn([
						new SwatchesColor('#00FF02'),
						new SwatchesColor('#D9EBD3'),
						new SwatchesColor('#B6D7A8'),
						new SwatchesColor('#93C47D'),
						new SwatchesColor('#6AA84F'),
						new SwatchesColor('#274E13'),
					]),
					//Cyan
					new SwatchesCategoryColumn([
						new SwatchesColor('#01FFFF'),
						new SwatchesColor('#CFE0E3'),
						new SwatchesColor('#A2C4CA'),
						new SwatchesColor('#76A5AF'),
						new SwatchesColor('#44818E'),
						new SwatchesColor('#0C343D'),
					]),
					//Pastell blue
					new SwatchesCategoryColumn([
						new SwatchesColor('#4A86E8'),
						new SwatchesColor('#C9D9F8'),
						new SwatchesColor('#A4C2F4'),
						new SwatchesColor('#6D9EEB'),
						new SwatchesColor('#3D78D8'),
						new SwatchesColor('#1C4587'),
					]),
					//Blue
					new SwatchesCategoryColumn([
						new SwatchesColor('#0000FF'),
						new SwatchesColor('#CFE2F3'),
						new SwatchesColor('#9FC5E7'),
						new SwatchesColor('#6FA8DC'),
						new SwatchesColor('#3D85C6'),
						new SwatchesColor('#073763'),
					]),
					//Purple
					new SwatchesCategoryColumn([
						new SwatchesColor('#9900FF'),
						new SwatchesColor('#D9D2E9'),
						new SwatchesColor('#B4A7D7'),
						new SwatchesColor('#8E7CC3'),
						new SwatchesColor('#674EA7'),
						new SwatchesColor('#20124D'),
					]),
					//Some strange color between purple and red
					new SwatchesCategoryColumn([
						new SwatchesColor('#A8276B'),
						new SwatchesColor('#EAD1DC'),
						new SwatchesColor('#D5A6BD'),
						new SwatchesColor('#C27BA0'),
						new SwatchesColor('#A64D79'),
						new SwatchesColor('#4C1130'),
					]),
				],
			),
		]);
	}
}

export class SwatchesConfig {
	constructor(public categories?: SwatchesCategory[]) {}
}

export class SwatchesColor {
	constructor(
		public color?: string,
		public name?: string,
	) {}
}

export class SwatchesCategoryColumn {
	constructor(public colors?: SwatchesColor[]) {}
}

export class SwatchesCategory {
	constructor(
		public colors?: SwatchesColor[],
		public columns?: SwatchesCategoryColumn[],
		public name?: string,
	) {}
}
