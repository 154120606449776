import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslatorModel } from 'shared/models/translator.model';
import { LandingPageService } from 'shared/services/landingPage.service';
import { TextService } from 'shared/services/text.service';
import { TranslationState } from 'shared/enums/translationState.enum';
import { LandingPageModel } from 'shared/models/landingPage.model';
import { TranslationModel } from 'shared/models/translation.model';
import { UIConfirmDialogResult, UIModule } from '@bannerflow/ui';
import { CommonModule } from '@angular/common';
import {
    BFDatePickerButton,
	BFDatePickerButtonOptions,
	BFInlineEditDirective,
	BFMenuConfig,
	BFMenuDirective,
	BFMenuItem,
	BFNotificationService,
	BFTagInputComponent,
} from '../../../../libs/material';
import { EllipsisMiddlePipe } from 'shared/pipes/ellipsisMiddle.pipe';
import { EditableTranslation } from './versions.component';
import { TranslationService } from 'design/translation.service';
import { SelectItemModel } from 'shared/models/selectItem.model';
import {
	ModalType,
	LPBConfirmDialogService,
} from 'shared/services/lpb-confirm-dialog.service';

@Component({
	styleUrls: ['translationRow.component.scss'],
	selector: '[translationRow]',
	templateUrl: 'translationRow.component.html',
	standalone: true,
	imports: [
		BFMenuDirective,
		FormsModule,
		BFInlineEditDirective,
		BFDatePickerButton,
		BFTagInputComponent,
		CommonModule,
		EllipsisMiddlePipe,
		UIModule,
	],
})
export class TranslationRowComponent implements OnInit {
	@HostBinding('class.translationRow')
	@Input('translationRow')
	translation: EditableTranslation;
	@Input('translationRowOtherTranslations') otherTranslations: any;
	@Input('translationRowValidation') validation: Function;
	@Input('translationRowIsOriginal') isOriginal: boolean;
	@Input('translationRowShowSendForTranslation')
	showSendForTranslation: boolean;

	public name: string;
	public publishSlug: string;
	public fallbackUrl: string;
	public deadlineSpan: Date[];
	public closeOnDeadline: boolean;
	public messageToTranslator: string;
	public notifyOnDone: string[];
	public translator: TranslatorModel;
	public isDirty = false;
	public loading: boolean;
	public showNewTranslatorView = false;
	public deadlineDatePickerOptions: BFDatePickerButtonOptions;
	public actionMenuConfig: BFMenuConfig;
	public today: Date = new Date();
	public showMoreOptions: boolean = false;
	public selectedTranslator: SelectItemModel;

	constructor(
		private readonly landingPageService: LandingPageService,
		private readonly notificationService: BFNotificationService,
		private readonly translationService: TranslationService,
		private readonly lpbDialogService: LPBConfirmDialogService,
		private readonly textService: TextService,
	) {}

	ngOnInit() {
		this.deadlineDatePickerOptions = {} as BFDatePickerButtonOptions;
		this.deadlineDatePickerOptions.isSpan = false;
		this.deadlineDatePickerOptions.timePicker = true;
		const minDate = new Date();
		minDate.setHours(0);
		minDate.setMinutes(0);
		minDate.setSeconds(0);
		minDate.setMilliseconds(0);
		this.deadlineDatePickerOptions.minDate = minDate;
		this.deadlineDatePickerOptions.initialDate = minDate;
		this.deadlineDatePickerOptions.closeOnPick = true;

		if (this.translation?.translatorsAndNone?.selectItems) {
			this.translation.translatorsAndNone.selectItems =
				this.translation.translatorsAndNone.selectItems.filter(
					(item) => item.label,
				);
			this.selectedTranslator =
				this.translation.translatorsAndNone.selectItems[0];
		}

		this.buildActionMenu();
	}

	public selectedChanged(selectedOption: SelectItemModel): void {
		this.selectedTranslator = selectedOption;
		this.translation.translation.translator = selectedOption.value;
		this.translation.translation.translationState = selectedOption.value
			? 1
			: 0;
	}

	public selectedEdit(selectedOption?: SelectItemModel): void {
		this.selectedTranslator = selectedOption;
		this.translator = selectedOption?.value;
		this.checkDirty();
	}

	private buildActionMenu() {
		let makeOriginalVersionMenuItem: BFMenuItem;
		if (this.landingPageService.dirty) {
			makeOriginalVersionMenuItem = new BFMenuItem(
				'Make original (Needs saving)',
				() => {
					this.showConfirmDialogForMakeVersionOriginal();
				},
				false,
				() => !this.landingPageService.dirty,
			);
		} else {
			makeOriginalVersionMenuItem = new BFMenuItem(
				'Make original',
				() => {
					this.showConfirmDialogForMakeVersionOriginal();
				},
			);
		}
		const deleteVersionMenuItem = new BFMenuItem('Delete version', () => {
			this.showConfirmDelete();
		});

		this.actionMenuConfig = new BFMenuConfig([
			makeOriginalVersionMenuItem,
			deleteVersionMenuItem,
		]);
		this.actionMenuConfig.options.placement = 'bottom-left';
		this.actionMenuConfig.options.offset = { top: -10, left: 0 };
	}

	private async showConfirmDialogForMakeVersionOriginal() {
		const name = this.translation.translation.name;
		const headerText = name
			? `Make this '${name}' original?`
			: 'Make this Translation Original?';
		const message = name
			? `Are you sure you want to make '${name}' the original version? The original version will act as the default version and is the reference for all other versions.`
			: 'Are you sure you want to delete this translation?';

		const configKey = ModalType.makeTranslationOriginal;
		const dialogConfig = {
			...this.lpbDialogService[configKey],
			headerText,
			text: message,
		};

		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialogWithMessages(
				[dialogConfig.text],
				ModalType.makeTranslationOriginal,
			);

		if (confirmResult === 'confirm') {
			try {
				const landingPage =
					await this.landingPageService.setOriginalTranslation(
						this.translation.translation.id,
					);
				// We set the current translation in the text service here, instead of in the landing page service.
				// Because there is a circular reference between text service and landing page service.
				if (
					this.textService.currentTranslation.id ===
					landingPage.originalTranslation.id
				) {
					this.textService.currentTranslation =
						landingPage.originalTranslation;
					this.textService.currentTranslationChange.emit(
						this.translation.translation,
					);
				}
				this.landingPageService.translationsChange.emit(true);
				this.notificationService.show(
					`The version '${this.translation.translation.name}' is now the original.`,
					'success',
					'top',
					3500,
					'finished',
				);
			} catch (err) {
				this.notificationService.show(
					`We couldn't update the original version.`,
					'error',
					'top',
					undefined,
					'alert',
				);
			}
		}
	}

	private updateTranslation(
		name: string,
		publishSlug: string,
		fallbackUrl: string,
		translator: TranslatorModel,
		deadline: Date,
		closeOnDeadline: boolean,
		notifyOnDone: string[],
		messageToTranslator: string,
	): Promise<void> {
		return new Promise<void>((resolve) => {
			let t = this.translation.translation;

			this.loading = true;
			this.translation.isLoading = true;

			let translationState = TranslationState.None;
			if (translator) {
				translationState =
					this.translation.translation.translator === translator
						? this.translation.translation.translationState
						: TranslationState.Pending;
			}

			let requestNumber = ++this.translation.requestNumber;

			this.landingPageService.get().then((lp: LandingPageModel) => {
				let update = new TranslationModel();
				update.id = t.id;
				update.localizationId = t.localizationId;
				update.culture = t.culture;

				update.name = name;
				update.publishSlug = publishSlug;
				update.fallbackUrl = fallbackUrl;
				update.translationState = translationState;
				update.translator = translator;

				update.deadline = deadline;
				update.closeOnDeadline = closeOnDeadline;
				update.notifyOnDone = notifyOnDone;
				update.messageToTranslator = messageToTranslator;

				this.translationService
					.update(
						lp.id,
						update,
						/*forceSendEmail*/ this.showNewTranslatorView,
					)
					.then((updatedTranslation: TranslationModel) => {
						if (this.translation.requestNumber === requestNumber) {
							t.name = name;
							t.publishSlug = publishSlug;
							t.fallbackUrl = fallbackUrl;
							t.translator = translator;
							t.translationState = translationState;
							t.deadline = deadline;
							t.closeOnDeadline = closeOnDeadline;
							t.notifyOnDone = notifyOnDone;
							t.messageToTranslator = messageToTranslator;

							this.translation.isLoading = false;
							this.loading = false;
							this.showNewTranslatorView = false;
							this.landingPageService.translationsChange.emit(
								true,
							);
							this.notificationService.show(
								'Your changes were saved',
								'success',
								'top',
								undefined,
								'finished',
							);

							resolve();
						}
					});
			});
		});
	}

	private cancelTranslation(): void {
		this.translator = null;
		let t = this.translation.translation;
		this.updateTranslation(
			t.name,
			t.publishSlug,
			t.fallbackUrl,
			this.translator,
			null,
			false,
			[],
			null,
		).then(() => {
			this.checkDirty();
		});
	}

	private delete(): void {
		this.translation.isLoading = true;
		this.translation.requestNumber++;

		this.landingPageService.get().then((landingPage: LandingPageModel) => {
			let translation = this.translation.translation;
			let removeId = translation.id;
			this.translationService
				.delete(landingPage.id, removeId)
				.then(() => {
					let msg = `${translation.name} was deleted from your Landing Page.`;
					this.notificationService.show(
						msg,
						'success',
						'top',
						undefined,
						'finished',
					);

					if (landingPage.translations) {
						landingPage.translations =
							landingPage.translations.filter((t) => {
								return t.id !== removeId;
							});
					}

					let removedTranslationWasSelected =
						this.textService.currentTranslation.id === removeId;
					if (removedTranslationWasSelected) {
						this.textService.setCurrentTranslation(
							landingPage.originalTranslation,
						);
					}

					this.landingPageService.translationsChange.emit(true);

					this.translation.isLoading = false;
				})
				.catch(() => {
					this.translation.isLoading = false;
				});
		});
	}

	private sendTranslationRequest(translationId: string): void {
		this.landingPageService.get().then((landingPage) => {
			this.translationService
				.sendTranslationRequest(landingPage.id, translationId)
				.then(() => {
					this.notificationService.show(
						'Translation was resent to the translator',
						'success',
						'top',
						undefined,
						'finished',
					);
				})
				.catch(() => {
					this.notificationService.show(
						'Could not send translation request. Please try again later.',
						'error',
						'top',
						undefined,
						'alert',
					);
				});
		});
	}

	/*** VIEW FUNCTIONS ***/

	public async showConfirmCancelTranslation(): Promise<void> {
		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialog(ModalType.cancelTranslation);

		if (confirmResult === 'confirm') {
			this.cancelTranslation();
		}
	}

	public async sendTranslationToNewTranslator() {
		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialog(ModalType.addTranslation);

		if (confirmResult === 'confirm') {
			this.translator = this.translation.translators.selectItems[0].value;
			this.deadlineSpan = [];
			this.showNewTranslatorView = true;
			this.checkDirty();
		}
	}

	public async showConfirmSendTranslationRequest(): Promise<void> {
		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialog(ModalType.resendTranslation);

		if (confirmResult === 'confirm') {
			this.sendTranslationRequest(this.translation.translation.id);
		}
	}

	private async showConfirmDelete(): Promise<void> {
		const message = this.name
			? `Are you sure you want to delete ${this.name}? Once it's gone, it's gone for good...`
			: 'Are you sure you want to delete this translation?';

		const confirmResult: UIConfirmDialogResult =
			await this.lpbDialogService.showDialogWithMessages(
				[message],
				ModalType.deleteTranslation,
			);

		if (confirmResult === 'confirm') {
			this.delete();
		}
	}

	public toggleDeadline(): void {
		if (this.deadlineSpan[0]) {
			this.deadlineSpan = [];
			this.closeOnDeadline = false;
		} else {
			this.deadlineSpan = [new Date()];
			this.deadlineSpan[0].setHours(0, 0, 0);
		}
	}

	public goToTranslation(): void {
		this.landingPageService.get().then((lp: LandingPageModel) => {
			let t = this.translation.translation;
			// note that admins access the translation as a translator,
			// meaning if the translation isn't started navigation fails
			let url = `/translation/${lp.accountSlug}/${lp.brandId}/${lp.id}/${t.id}/${t.translator.id}`;
			window.open(url);
		});
	}

	public openEdit(event: MouseEvent): void {
		// init editable values to mimic model values
		let t = this.translation.translation;
		this.name = t.name;
		this.publishSlug = t.publishSlug;
		this.fallbackUrl = t.fallbackUrl;
		this.translator = t.translator;
		this.deadlineSpan = [t.deadline];
		this.closeOnDeadline = t.closeOnDeadline;
		this.messageToTranslator = t.messageToTranslator;
		this.notifyOnDone = t.notifyOnDone ? t.notifyOnDone.map((a) => a) : [];

		this.translation.isEditing = true;
		// run validation on slug just to reach the code where the overall isValid will be set to false since we now have a translation with isEdit true
		this.validation(
			this.translation.translation.publishSlug,
			this.translation,
		);

		this.isDirty = false;

		event.stopPropagation();
	}

	public toggleMoreOptions(): void {
		this.showMoreOptions = !this.showMoreOptions;
	}

	public checkDirty(): void {
		let t = this.translation.translation;

		if ((this.name || '') != (t.name || '')) {
			this.isDirty = true;
			return;
		}

		if ((this.fallbackUrl || '') !== (t.fallbackUrl || '')) {
			this.isDirty = true;
			return;
		}

		if ((this.publishSlug || '') !== (t.publishSlug || '')) {
			this.isDirty = true;
			return;
		}

		if ((this.selectedTranslator.value || '') !== (t.translator || '')) {
			this.isDirty = true;
			this.showMoreOptions =
				this.selectedTranslator.value === null
					? false
					: this.showMoreOptions;
			return;
		}

		// since the dirty flag is only used by existing translations there's no need to check other translator props

		this.isDirty = false;
	}

	public closeEdit(): void {
		// set to false BEFORE running validation so it won't fail (doesn't allow any row to be in edit mode)
		this.showMoreOptions = false;
		this.translation.isEditing = false;
		this.showNewTranslatorView = false;
		this.translator = null;
		this.validation(
			this.translation.translation.publishSlug,
			this.translation,
		);
	}

	public save(): void {
		this.showMoreOptions = false;
		this.updateTranslation(
			this.name,
			this.publishSlug,
			this.fallbackUrl,
			this.translator,
			this.deadlineSpan[0],
			this.closeOnDeadline,
			this.notifyOnDone,
			this.messageToTranslator,
		).then(() => {
			this.closeEdit();
		});
	}

	public confirm() {
		this.showMoreOptions = false;
		let t = this.translation.translation;

		// write values to model
		t.name = this.name;
		t.fallbackUrl = this.fallbackUrl;
		t.deadline = this.deadlineSpan[0];
		t.closeOnDeadline = this.closeOnDeadline;
		t.messageToTranslator = this.messageToTranslator;
		t.notifyOnDone = this.notifyOnDone
			? this.notifyOnDone.map((a) => a)
			: [];
		t.translator = this.translator;
		t.translationState = t.translator
			? TranslationState.Pending
			: TranslationState.None;

		this.translation.isEditing = false;
		this.validation(this.publishSlug, this.translation).then(
			(translation: any) => {
				t.publishSlug = this.publishSlug;
			},
		);
	}

	public validate() {
		this.validation(this.publishSlug, this.translation).then(
			(slug: any) => {
				this.publishSlug = slug;
			},
		);
	}
}
