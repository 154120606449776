<ng-container *ngIf="view != 'versions'">
<div class="header">

    <div class="header__section header__section--left">
        <div class="header__menu">
            <div class="header__menuItem header__menuItem--primary">
            </div>
            <div class="header__menuItem"
                 *ngIf="view === 'design'"
                 bfTooltip="Translatable content"
                 bfTooltipPlacement="bottom"
                 [ngClass]="{ 'header__menuItem--active' : appService.sideBarView == 1 }"
                 (click)="appService.toggleTranslationSideBar()">
                <i class="bf-icon bf-icon-translation"></i>
            </div>
            <div class="header__menuItem"
                 *ngIf="view === 'code'"
                 bfTooltip="File list"
                 bfTooltipPlacement="bottom"
                 [ngClass]="{ 'header__menuItem--active' : appService.sideBarView == 2 }"
                 (click)="appService.toggleFilesSideBar()">
                <i class="bf-icon bf-icon-file-list"></i>
            </div>
            <div *ngIf="landingPage" class="header__title">
                {{landingPage.name}}
            </div>
        </div>
    </div>

    <div class="header__section header__section--center"
         *ngIf="view === 'design'">
        <div class="header__sizePicker"
             *ngIf="designService.artboardSize"
             bfTooltip="Change the size of your artboard"
             bfTooltipPlacement="bottom"
             [bfMenu]="sizeMenuConfig">
            <span>{{designService.artboardSize.name}}</span>&nbsp;
            <i class="bf-icon bf-icon-caret-down"></i>
        </div>
        <div class="header__versionPicker"
             *ngIf="textService.currentTranslation"
             bfTooltip="Change to another version or manage all versions."
             bfTooltipPlacement="bottom"
             [bfMenu]="translationsMenuConfig">
            <ui-flag size="tiny" [culture]="textService.currentTranslation.culture"></ui-flag>
            {{ textService.currentTranslation.name }}&nbsp;
            <i class="bf-icon bf-icon-caret-down"></i>
        </div>
    </div>

    <div class="header__section header__section--right" ui-theme="minimal">
        <environment-picker />
        <div class="header__state"
             *ngIf="landingPage">
            <div class="header__stateText"
                 [bfTooltip]="TranslationModel.getStateDescription(textService.currentTranslation)"
                 bfTooltipPlacement="bottom">
                Status:
                <span>{{ TranslationModel.getStateText(textService.currentTranslation) }}</span>
            </div>
        </div>
        <ui-button
          #saveButton
          id="interaction-save-landingpage-btn"
          type="primary"
          svgIcon="files"
          text="Save"
          (click)="save()"
          [disabled]="!landingPageService.dirty"
          [loading]="isSaving"
          [uiTooltip]="'Use ' + (UtilsService.isMac() ? 'cmd' : 'ctrl') + '+s to save'"
          uiTooltipPosition="bottom"
          [uiTooltipDelay]="0" />
        <ui-button
          #publishButton
          *ngIf="textService && textService.currentTranslation && (textService.currentTranslation.publishState <= 1 || textService.currentTranslation.publishState === 3)"
          id="interaction-publish-update-landingpage-btn"
          type="primary"
          svgIcon="cloud"
          (click)="publish(textService.currentTranslation)"
          [text]="textService && textService?.currentTranslation?.publishState === 3 ? 'Update' : 'Publish'" />
        <ui-button
          #unpublishButton
          *ngIf="textService && textService.currentTranslation && textService?.currentTranslation?.publishState > 1"
          id="interaction-unpublish-landingpage-btn"
          type="default"
          svgIcon="disabled"
          text="Unpublish"
          (click)="unpublish(textService.currentTranslation)">
        </ui-button>
    </div>
</div>
</ng-container>
