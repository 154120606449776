// libs
import { Component, ViewContainerRef } from '@angular/core';

import {BFMaterial, BFTooltipDirective} from '../../../../libs/material/index';
import {DatePipe, NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {EllipsisMiddlePipe} from "shared/pipes/ellipsisMiddle.pipe";
import {ExternalTranslationModel} from "externalTranslation/externalTranslation.model";
import {ActivatedRoute} from "@angular/router";
import {ApiService} from "shared/services/api.service";
import {TranslatorService} from "externalTranslation/translator.service";
import {TranslationState} from "shared/enums/translationState.enum";
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'translatorDashboardView',
    styleUrls: ['translatorDashboard.view.component.scss'],
    templateUrl: 'translatorDashboard.view.component.html',
    standalone: true,
  imports: [NgIf, NgFor, NgClass, BFTooltipDirective, NgSwitch, NgSwitchCase, DatePipe, EllipsisMiddlePipe, UIModule]
})
export class TranslatorDashboardViewComponent {

    public translationsInProgress: ExternalTranslationModel[];
    public translationsPending: ExternalTranslationModel[];
    public translationsDone: ExternalTranslationModel[];

    constructor(private readonly bfMaterial: BFMaterial,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly activatedRoute: ActivatedRoute,
        private readonly apiService: ApiService,
        private readonly translatorService: TranslatorService) {
        this.activatedRoute.params.subscribe((params: any) => {

            this.translatorService.init(params.translatorId);

            this.translatorService.getTranslations()
                .then((externalTranslations: ExternalTranslationModel[]) => {

                    this.translationsDone = [];
                    this.translationsPending = [];
                    this.translationsInProgress = [];

                    for (let t of externalTranslations) {
                        const hasPassedDeadline = t.deadline && (t.deadline.valueOf() < Date.now());

                        if (t.closeOnDeadline && hasPassedDeadline || t.translationState === TranslationState.Done) {
                            this.translationsDone.push(t);
                        } else if (t.translationState === TranslationState.Pending) {
                            this.translationsPending.push(t);
                        } else if (t.translationState === TranslationState.InProgress) {
                            this.translationsInProgress.push(t);
                        }
                    }
                });
        });

        this.bfMaterial.init(viewContainerRef);
    }

    public startTranslation(translation: ExternalTranslationModel): void {
        const translatorId = this.translatorService.translatorId;
        const action = `${translation.accountSlug}/${translation.brandId}/take?translationId=${translation.translationId}&translatorId=${translatorId}&landingPageId=${translation.landingPageId}`;
        this.apiService.get('externalTranslation', action, true)
            .then(() => {
                //TODO: Fix this better, is used because the landingpageService.init in translationMain.view.component
                //not handling multiple translations/landingpages.
                window.location.replace(`/translation/${translation.accountSlug}/${translation.brandId}/${translation.landingPageId}/${translation.translationId}/${translatorId}`);
            })
            .catch((r) => {
                console.warn(r);
            });
    }
}
