import { Component, Input, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { BFMenuItem, BFMenuConfig } from '../index';
import { BFPositionService, BFUtilsService } from '../../../services/index'
import { CommonModule } from '@angular/common';
import {UIModule} from "@bannerflow/ui";

@Component({
    selector: 'bfMenu',
    templateUrl: './bfMenu.list.component.html',
    styleUrls: ['./bfMenu.list.component.scss'],
    standalone: true,
  imports: [CommonModule, UIModule]
})
export class BFMenuComponent implements AfterViewInit, OnDestroy {
    public menuConfig: BFMenuConfig;
    public position: any = {};

    private resolve: Function;
    private reject: Function;
    private componentElement: HTMLElement;
    private targetElementOrPosition: any;
    private onBodyClick: Function;
    private onBodyRightClick: Function;
    private closeTimeout: any;

    constructor(
        private elementRef: ElementRef,
        private positionService: BFPositionService,
        private Renderer2: Renderer2
    ) {
        this.position.left = 200;
        this.position.top = 200;
    }

    public ngAfterViewInit(): void {
        setTimeout(() => {
            this.positionMenu();
        });
    }

    public initiate(menuConfig: BFMenuConfig, targetElementOrPosition: any): Promise<BFMenuItem> {
        this.menuConfig = menuConfig;

        this.handleMenuItems();

        this.targetElementOrPosition = targetElementOrPosition;
        this.componentElement = this.elementRef.nativeElement.children[0];

        // Hide element so no flickering while positioning element
        this.componentElement.style.opacity = '0';

        this.positionMenu();

        setTimeout(() => {
            // Show element after positioning element
            this.componentElement.style.opacity = '1';
        }, 50);

        // Timeout need to not trigger close by opening mouseclick
        this.closeTimeout = setTimeout(() => {
            this.onBodyClick = this.Renderer2.listen('document', 'click', (event: MouseEvent) => {
                if (!BFUtilsService.isElementInElement(event.target, this.componentElement)) {
                    this.cancel();
                    event.stopPropagation();
                    event.preventDefault();
                }
            });
            this.onBodyRightClick = this.Renderer2.listen('document', 'contextmenu', (event: MouseEvent) => {
                event.preventDefault();
                event.stopPropagation();
                this.cancel();
            });
        }, 10);

        return new Promise<BFMenuItem>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }

    private handleMenuItems(): void {
        this.menuConfig.menuItems.forEach((menuItem: BFMenuItem) => {
            if (menuItem.shouldBeActive) {
                menuItem.disabled = !menuItem.shouldBeActive();
            }
        });
    }

    public onSelect(item: BFMenuItem, event: MouseEvent): void {
        if (item.isSeperator) {
            return;
        }

        if (item.onSelect) {
            item.onSelect(item, event);
        }

        // Checkbox item
        if (!this.menuConfig.options.closeOnSelect) {
            return;
        }

        this.resolve(item);
    }

    private positionMenu(): void {
        let options = this.menuConfig.options;

        if (options.width > 0) {
            (this.componentElement as any).style.width = `${options.width}px`;
        }

        // Check if popover fits in window
        this.position = this.positionService.smartPosition(
            this.targetElementOrPosition,
            this.componentElement,
            options.placement,
            true,
            options.offset);

        // Check if left is overflowed
        if (this.position.left < 0) {
            this.position.left = 0;
            return;
        }
    }

    private cancel(): void {
        this.reject();
    }

    public ngOnDestroy(): void {
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout)
        }
        if (this.onBodyClick) {
            this.onBodyClick();
        }
        if (this.onBodyRightClick) {
            this.onBodyRightClick();
        }
    }
}
