import moment from 'moment';
import {ISerializable} from "shared/interfaces/serializable";
import {TextModel} from "shared/models/text.model";
import {ResourceTranslationModel} from "shared/models/resourceTranslation.model";
import {PublishState} from "shared/enums/publishState.enum";
import {State} from "shared/enums/state.enum";
import {TranslatorModel} from "shared/models/translator.model";
import {TranslationState} from "shared/enums/translationState.enum";



export class TranslationModel implements ISerializable<TranslationModel> {
    public id: string;
    public localizationId: string;
    public name: string;
    public fallbackUrl: string;
    public culture: string;
    public cultureName: string;
    public texts: TextModel[];
    public resourceTranslations: ResourceTranslationModel[] = [];
    public publishState: PublishState;
    public state: State = State.Modified;
    public publishSlug: string;
    public translator: TranslatorModel;
    public translationState: TranslationState;
    public deadline: Date;
    public closeOnDeadline;
    public notifyOnDone: string[];
    public messageToTranslator: string;
    public lastPublished: Date;
    public lastUnpublished: Date;

    public selected: any;

    // TODO: this model is now different depending on which view it is for (translation and lp controllers return different models)
    public isClosedByDeadline: boolean;

    public deserialize(data: any): TranslationModel {
        this.id = data.id;
        this.localizationId = data.localizationId;
        this.name = data.name;
        this.fallbackUrl = data.fallbackUrl;
        this.resourceTranslations = [];
        this.publishState = data.publishState;
        this.publishSlug = data.publishSlug;
        this.translationState = data.translationState;
        this.deadline = data.deadline ? new Date(data.deadline) : null;
        this.closeOnDeadline = data.closeOnDeadline;
        this.notifyOnDone = data.notifyOnDone;
        this.messageToTranslator = data.messageToTranslator;
        this.isClosedByDeadline = data.isClosedByDeadline;
        this.lastPublished = data.lastPublished ? new Date(data.lastPublished) : null;
        this.lastUnpublished = data.lastUnPublished ? new Date(data.lastUnPublished) : null;

        if (data.translator)
            this.translator = new TranslatorModel().deserialize(data.translator);

        if (data.texts) {
            this.texts = [];
            data.texts.forEach(text => {
                this.texts.push(new TextModel().deserialize(text));
            });
        }

        if (data.resourceTranslations) {
            data.resourceTranslations.forEach(resource => {
                this.resourceTranslations.push(new ResourceTranslationModel().deserialize(resource));
            });
        }

        this.state = State.Modified;
        this.culture = data.culture;
        this.cultureName = data.cultureName;

        return this;
    }

    // TODO: handle translation.lastPublished == null
    public static getStateDescription(translation: TranslationModel): string {
        switch (translation.publishState) {
            case PublishState.Published:
                return 'Version was last published ' + moment(translation.lastPublished).fromNow();
            case PublishState.PublishedWithPendingUpdates:
                return `Version was last published ${moment(translation.lastPublished).fromNow()}. But some pending changes has not been published.`;
            case PublishState.Unpublished:
                let msg = "Version is not published.";
                if (translation.lastUnpublished)
                    msg += ' Was unpublished ' + moment(translation.lastUnpublished).fromNow() + '.';
                return msg;
            default:
                return 'N/A';
        }
    }

    public static getStateText(translation: TranslationModel): string {
        switch (translation.publishState) {
            case PublishState.Published:
                return 'Published';
            case PublishState.PublishedWithPendingUpdates:
                return 'Pending changes';
            case PublishState.Unpublished:
                return 'Not published';
            default:
                return 'N/A';
        }
    }
}
