<div class="fileBrowser__file fileBrowser__file--indent{{depth}}"
    [ngClass]="{ 'fileBrowser__file--selected' : isActive }"
     *ngIf="stateEnum.Deleted !== item.state"
    (click)="onFileSelect($event)"
    [bfContextMenu]="config">
    <i class="bf-icon bf-icon-fw"
       [ngClass]="{ 'bf-icon-file' : !item.isImage(), 'bf-icon-file-image' : item.isImage() }"></i>
    <i class="fileBrowser__localizedFileIcon bf-icon bf-icon-globe"
       *ngIf="item.translatable"></i>
    <div class="fileBrowser__fileNameContainer">
        <div  #bfInlineEditReference=bfInlineEdit
                *ngIf="!locked"
                [(bfInlineEdit)]="itemName"
                [bfInlineEditValidation]="validateName"
                (bfInlineEditAbort)="onAbort()"
                (bfInlineEditDone)="onItemNameChange($event)"
                (bfInlineEditEdit)="onItemNameEdit($event)"
                [bfInlineEditPreventClickToEdit]="true"
                class="fileBrowser__fileName"></div>
        <span class="fileBrowser__fileNameSpan" *ngIf="locked" >{{item.name}}</span>
        <div class="fileBrowser__fileNameError" *ngIf="!validName">{{invalidMessage}}</div>
    </div>
    <span class="fileBrowser__fileButtons dots" *ngIf="!locked">
        <i class="bf-icon bf-icon-fw bf-icon-more dots-icon" [bfMenu]="config"></i>
    </span>

    <i class="fileBrowser__fileNew" *ngIf="stateEnum.New === item.state || stateEnum.Modified === item.state"></i>
</div>
