<div class="bfMenu bfMenu--{{ menuConfig.options.placement }} {{ menuConfig.options.class }}"
    [ngClass]="{ 'bfMenu--showArrow': menuConfig.options.showArrow }"
    [ngStyle]="{ left: position.left + 'px', top: position.top + 'px' }">
    <div class="bfMenu__list">
        <div class="bfMenu__item"
             *ngFor="let item of menuConfig.menuItems"
             (click)="onSelect(item, $event)"
             [ngClass]="{ 'bfMenu__item--seperator' : item.isSeperator, 'bfMenu__item--disabled' : item.disabled, 'bfMenu__item--selected' : item.selected }">
            <ui-flag size="tiny" *ngIf="item.flag && !item.isSeperator"
                    [culture]="item.flag"></ui-flag>
            <i class="bfMenu__icon bf-icon bf-icon-{{ item.icon }} bf-icon-fw"
               *ngIf="item.icon && !item.isSeperator"></i>

            <div class="bfMenu__checkbox">
                <!-- Couldn't find the component -->
                <!-- <bfCheckbox *ngIf="!item.flag && !item.isSeperator && !item.icon && item.selected !== undefined"
                        [checked]="item.selected"></bfCheckbox> -->
            </div>
            <span class="bfMenu__label"
                *ngIf="!item.isSeperator && menuConfig.options.useUnsafeHtml"
                innerHTML="{{ item.label }}"></span>
            <span class="bfMenu__label"
                *ngIf="!item.isSeperator && !menuConfig.options.useUnsafeHtml">
                {{ item.label }}
            </span>
            <span class="bfMenu__description"
                *ngIf="item.description && !menuConfig.options.useUnsafeHtml">
                {{ item.description }}
            </span>
        </div>
    </div>
</div>
