<div class="fileBrowser">
    <div class="fileBrowser__header">
        <div class="fileBrowser__headerName">
            {{ landingPage ? landingPage.name : '' }}
        </div>
        <div class="fileBrowser__buttons">
            <i class="bf-icon bf-icon-fw bf-icon-plus-smallfatty"
               (click)="addFile()"
               bfTooltip="Create a new file"></i>
            <i class="bf-icon bf-icon-fw bf-icon-upload"
               (click)="uploadFiles()"
               bfTooltip="Upload any file(s)"></i>
               <i class="bf-icon bf-icon-fw bf-icon-download"
               bfTooltip="Download all files as ZIP"
               (click)="downloadZip()"></i>
        </div>  
    </div>

    <div class="fileBrowser__scrollView">
        <fileBrowserFile [item]="indexFile"
                         [depth]="0"
                         [locked]="true" />

        <fileBrowserFolder #rootFolderComponent
                           [item]="rootFolder"
                           [depth]="0" />
    </div>
</div>





