// libs
import {
    Component,
    OnInit,
    AfterViewInit,
    Input,
    ViewChild
} from '@angular/core';
import {
  BFDialogResponse,
  BFMenuItem,
  BFMenuDirective,
  BFInlineEditDirective,
BFContextMenuDirective
} from "../../../../../../../libs/material/index";
import {CommonModule} from "@angular/common";
import {FileBrowserItem} from "code/views/code/components/fileBrowser/fileBrowserItem/fileBrowserItem";
import {CodeService} from "code/services/code.service";
import {DocumentService} from "code/services/document.service";
import {TextService} from "shared/services/text.service";
import {LandingPageService} from "shared/services/landingPage.service";
import {UploadDialogService} from "shared/components/uploadDialog/uploadDialog.service";
import {FileModel} from "shared/models/file.model";
import {FileType} from "shared/enums/fileType.enum";
import {State} from "shared/enums/state.enum";
import { LPBConfirmDialogService, ModalType } from 'shared/services/lpb-confirm-dialog.service';
import { UIConfirmDialogResult } from '@bannerflow/ui';

@Component({
    styleUrls: ['fileBrowserFile.component.scss'],
    selector: 'fileBrowserFile',
    templateUrl: 'fileBrowserFile.component.html',
    inputs: ['item', 'depth', 'parentFolder'],
    outputs: ['fileSelect'],
    standalone: true,
    imports: [CommonModule, BFContextMenuDirective, BFInlineEditDirective, BFMenuDirective]
})
export class FileBrowserFileComponent extends FileBrowserItem implements OnInit, AfterViewInit {
    @Input() public locked: boolean;
    @ViewChild('bfInlineEditReference') private inlineEditDirective: BFInlineEditDirective;

    public isActive: boolean;
    private translateActionMenuItem: BFMenuItem;
    private activeDocumentSubscription: any;

    constructor(
        private readonly _codeService: CodeService,
        private readonly _lpbDialogService: LPBConfirmDialogService,
        private readonly _documentService: DocumentService,
        private readonly textService: TextService,
        private readonly landingPageService: LandingPageService,
        private readonly uploadDialogService: UploadDialogService) {

        super(_codeService, _lpbDialogService, _documentService);

        this.activeDocumentSubscription = this.documentService.activeDocumentChange.subscribe((document: Document) => {
            if (document)
                this.isActive = this.documentService.isFileActive(this.item as FileModel);
            else
                this.isActive = false;
        });
    }

    ngOnInit() {
        if (this.locked) {
            this.config = null;
            return;
        }

        this.isActive = this.documentService.isFileActive(this.item as FileModel);

        this.setContextMenuItems();
        this.itemName = this.item.name;
    }

    private setContextMenuItems() {
        this.setInitialConfigMenu();

        let file = (this.item as FileModel);
        let itemIsImage = file.type === FileType.Image;

        this.config.menuItems.unshift(
            new BFMenuItem('Download', () => {
                this.codeService.downloadFile(this.item as FileModel);
            },
                false,
                () => {
                    return file.state != State.New
                })
        );

        if (itemIsImage) {
            this.config.menuItems.unshift(new BFMenuItem('Replace Image', () => {
                this.uploadDialogService
                    .show(false, 'image/*', 'Replace image in this version', 'sidebar')
                    .then(async (response: BFDialogResponse<FileModel[]>) => {
                        if (!response.cancel) {
                            let file = response.data[0];
                            const originalResourceId = (this.item as FileModel).id;
                            const document = this.documentService.getDocumentWithFile(this.item as FileModel);
                            const state = this.item.state === State.New ? State.New : State.Modified;
                            const isReplacingNewOne = state === State.New;
                            const isTranslatable = (this.item as FileModel).translatable;
                            if (isTranslatable) {
                                await this.textService.updateResourceInAllTranslations(file, originalResourceId, isReplacingNewOne);
                            }
                            const landingPage = await this.landingPageService.get();
                            landingPage.rootFolder.files.some((f) => {
                                if (f.id === originalResourceId) {
                                    f.replaceWithImageData(file);
                                    if (isReplacingNewOne) {
                                        f.id = file.id;
                                    }
                                    f.state = state;
                                    file = f;
                                    return true;
                                }
                            });
                            if (document) {
                                document.file = file;
                            }
                            this.documentService.openFile(file);
                            this.landingPageService.toggleDirty(true);
                            (this.item as FileModel).state = state;
                        }
                    });
            }));

            this.translateActionMenuItem = new BFMenuItem(((this.item as FileModel).translatable ? 'Cancel' : 'Enable') + ' translations',
                () => {
                    this.toggleTranslatable().then(() => {
                        this.setContextMenuItems();
                    });
                });

            this.config.menuItems.unshift(this.translateActionMenuItem);
        }
    }

    ngAfterViewInit() {
        // Only do this if the item is not the rootFolder or index.html
        if (this.inlineEditDirective) {
            this.initFileItem(this.inlineEditDirective);
        }
    }

    private toggleTranslatable = async (): Promise<void> => {
        let isTranslatable = (this.item as FileModel).translatable;

        // if being unflagged, show warning
        if (isTranslatable) {
            const confirmResult: UIConfirmDialogResult =
                await this.lpbDialogService.showDialog(
                ModalType.cancelFileTranslationConfig,
                );

            if (confirmResult === 'confirm') {
                try {
                    this.setTranslatable(!isTranslatable);
                }
                catch (err) {
                    return;
                }
            }
        } else {
            this.setTranslatable(!isTranslatable);
            return Promise.resolve();
        }
    };

    private setTranslatable(translatable: boolean) {
        // Toggle translatable
        let file = (this.item as FileModel);
        file.translatable = translatable;
        if (this.item.state !== this.stateEnum.New) {
            this.item.state = this.stateEnum.Modified;
        }

        if (file.translatable) {
            this.textService.addTranslatableFile(file);

        } else {
            // Delete file here
            this.textService.removeTranslatableFile(file);
        }

        if (this.translateActionMenuItem) {
            let index = this.config.menuItems.indexOf(this.translateActionMenuItem);
            this.config.menuItems.splice(index, 1);
        }


        this.translateActionMenuItem = new BFMenuItem(
            (file.translatable ? 'Cancel' : 'Enable') + ' translations',
            () => { this.toggleTranslatable(); });

        // Change naming in menu
        this.config.menuItems.unshift(this.translateActionMenuItem);
    }

    public onFileSelect($event) {
        // Select file only if the user didn't click the settings icon
        if (!$event.target.classList.contains('bf-icon-more'))
            this.documentService.openFile(this.item as FileModel);
    }

    ngOnDestroy() {
        if (this.activeDocumentSubscription) {
            this.activeDocumentSubscription.unsubscribe();
        }
    }
}
