<bfDialog (onClose)="cancel()">
    <ui-loader *ngIf="loading"></ui-loader>
    <div class="uploadDialog">
        <ui-loader *ngIf="loading"></ui-loader>
        <h3>{{ title }}</h3>
        <div class="uploadDialog__files"
             *ngIf="selectedFiles.length">
            <div class="uploadDialog__file"
                 *ngFor="let file of selectedFiles">
                {{ file.name | ellipsisMiddle:50 }} | {{ file.size | bfBytes }}
                <i class="bf-icon bf-icon-trash"
                   (click)="removeFile(file)"></i>
            </div>
        </div>
        <div class="uploadDialog__addFiles"
             *ngIf="multiple || (!multiple && selectedFiles.length === 0)">
            {{ multiple ? 'Add files...' : 'Select file...' }}
            <input #fileInput
                   class="uploadDialog__addFilesInput"
                   (change)="selectFiles($event)"
                   [attr.multiple]="multiple"
                   [attr.accept]="allowedFiles"
                   type="file"/>
        </div>
        <div class="uiDialog__buttons">
            <ui-button
                id="interaction-cancel-upload-dialog-landingpage-btn"
                type="default"
                (click)="cancel()"
                text="Cancel">
            </ui-button>
            <ui-button
                *ngIf="zipChosen"
                id="interaction-migration-upload-dialog-landingpage-btn"
                type="default"
                (click)="migrate()"
                text="Migrate old Landing page">
            </ui-button>
            <ui-button
                id="interaction-upload-dialog-landingpage-btn"
                type="primary"
                [disabled]="selectedFiles.length === 0"
                (click)="upload()"
                text="Upload">
            </ui-button>
        </div>
    </div>
</bfDialog>
