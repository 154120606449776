import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component, inject,
  OnInit,
} from '@angular/core';
import { UIModule } from '@bannerflow/ui';

import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { environment } from './../../../../environments/environment'

@Component({
	selector: 'environment-picker',
	templateUrl: 'environment-picker.component.html',
	styleUrls: ['environment-picker.component.scss'],
	standalone: true,
	imports: [CommonModule, UIModule]
})
export class EnvironmentPickerComponent implements OnInit {
  selectedBranch = '';
  pullRequests$: Observable<string[]>;
  isProduction: boolean;

  private environmentService = inject(EnvironmentService);

  constructor() {
    const url = new URL(window.location.href);
    this.selectedBranch = url.searchParams.get('branch') || 'Main';
  }

  ngOnInit() {
    this.isProduction = environment.NAME === 'production'
    if (!this.isProduction) {
      this.pullRequests$ = this.environmentService.getPullRequests();
    }
  }

  selectedChanged(branch: string): void {
    this.selectedBranch = branch;
    const url = new URL(window.location.href);
    if (branch && branch !== 'Main') {
      url.searchParams.set('branch', branch);
    } else {
      url.searchParams.delete('branch');
    }
    window.location.href = url.href;
  }
}
